import { Route } from '@angular/router';
import { RequisicaoDocumentoResolver } from '@shared/resolvers/requisicao-documento.resolver';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { NoAuthGuard } from 'app/core/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { RoleGuard } from './core/guards/role.guard';
import { BackofficeDashboardComponent } from './modules/backoffice/backoffice-dashboard/backoffice-dashboard.component';
import { CriarEditarRequisitacaoDocumentoComponent } from './modules/requisicao-documentos/criar-editar-requisicao-documento/criar-editar-requisicao-documento.component';
import { ExibirAprovarRequisicaoComponent } from './modules/requisicao-documentos/exibir-aprovar-requisicao/exibir-aprovar-requisicao.component';
import { DashboardComponent } from './modules/vendedor/dashboard/dashboard.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

  // Redirect empty path to '/example'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },

  // Redirect signed in user to the '/example'
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: '/vendedor/dashboard'
  },
  {
    path: 'signed-in-redirect-comprador',
    pathMatch: 'full',
    redirectTo: '/comprador/dashboard/geral'
  },
  {
    path: 'signed-in-redirect-backoffice',
    pathMatch: 'full',
    redirectTo: '/backoffice/dashboard'
  },
  {
    path: 'signed-in-redirect-vendedor',
    pathMatch: 'full',
    redirectTo: '/vendedor/dashboard'
  },
  // redirects
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },
  // Auth routes (guest)s
  {
    path: 'auth',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'forgot-password',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
      },
      {
        path: 'reset-password',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
      },
      {
        path: 'login',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
      },
      {
        path: 'cadastrar',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
      }
    ]
  },

  // Auth routes (logged in)
  {
    path: 'auth',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [{
      path: 'logout',
      loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
    },
    ]
  },
  // convite 
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [{
      path: 'convite',
      loadChildren: () => import('app/modules/auth/convite/convite.module').then(m => m.ConviteModule)
    },
      // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/errors/error-404/error-404.module').then(m => m.Error404Module)},
    ]

  },
  // Auth routes (logged in)

  // vendedor routes
  {
    path: 'vendedor',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      role: 'vendedor'
    },
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          role: 'vendedor'
        },
      },
      {
        path: 'cotacoes',
        data: {
          role: 'vendedor'
        },
        loadChildren: () => import('app/modules/vendedor/cotacoes/cotacoes.module').then(m => m.CotacoesModule)
      },
      {
        path: 'empresas',
        loadChildren: () => import('app/modules/vendedor/vendedor-fornecedor/vendedor-fornecedor.module').then(m => m.VendedorFornecedorModule)
      },
      {
        path: 'documentos',
        loadChildren: () => import('app/modules/vendedor/vendedor-documentos/vendedor-documeto.module').then(m => m.VendedorDocumentoModule)
      },
      {
        path: 'requisicao', 
        // loadChildren: () => import('app/modules/requisicao-documentos/requisicao-documentos.module').then(m => m.RequisicaoDocumentosModule)
        children: [
          {
            path: "exibir/:id",
            component: ExibirAprovarRequisicaoComponent,
            resolve: {
              requisicaoDocumento: RequisicaoDocumentoResolver
            }
          },
        ]
      },
      {
        path: 'modelo-documento',
        data: {
          role: 'vendedor'
        },
        loadChildren: () => import('app/modules/modelo-documento/modelo-documento.module').then(m => m.ModeloDocumentoModule)
      },
      {
        path: 'help-center',
        data: {
          role: 'vendedor'
        },
        loadChildren: () => import('app/modules/backoffice/help-center/help-center.module').then(m => m.HelpCenterModule)
      },

      {
        path: 'minhaconta',
        loadChildren: () => import('app/modules/vendedor/profile-vendedor/profile-vendedor.module').then(m => m.ProfileVendedorModule)
      },
      { path: 'error/403', loadChildren: () => import('app/modules/errors/error-403/error-403.module').then(m => m.Error403Module) },
      { path: 'error/404', loadChildren: () => import('app/modules/errors/error-404/error-404.module').then(m => m.Error404Module) },
      { path: '**', redirectTo: '/error/404' }
    ]
  },
  // Comprador routes
  {
    path: 'comprador',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      role: 'comprador'
    },
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@comprador/comprador-dashboard/comprador-dashboard.module').then(m => m.CompradorDashboardModule)
      },
      {
        path: 'fornecedores',
        loadChildren: () => import('app/modules/comprador/comprador-fornecedor/comprador-fornecedor.module').then(m => m.CompradorFornecedorModule)
      },
      {
        path: 'vendedores',
        loadChildren: () => import('app/modules/comprador/comprador-vendedores/comprador-vendedores.module').then(m => m.CompradorVendedoresModule)
      },
      {
        path: 'documentos',
        loadChildren: () => import('app/modules/comprador/comprador-documento/comprador-documento.module').then(m => m.CompradorDocumentoModule)
      },
      {
        path: 'requisicao',
        // loadChildren: () => import('app/modules/requisicao-documentos/requisicao-documentos.module').then(m => m.RequisicaoDocumentosModule)
        children: [
          {
            path: "exibir/:id",
            component: ExibirAprovarRequisicaoComponent,
            resolve: {
              requisicaoDocumento: RequisicaoDocumentoResolver
            }
          },
          {
            path: "criar",
            component: CriarEditarRequisitacaoDocumentoComponent,
          },
          {
            path: "editar/:id",
            component: CriarEditarRequisitacaoDocumentoComponent,
            resolve: {
              requisicaoDocumento: RequisicaoDocumentoResolver
            }
          },
        ]
      },
      {
        path: 'modelo-documento',
        data: {
          role: 'comprador'
        },
        loadChildren: () => import('app/modules/modelo-documento/modelo-documento.module').then(m => m.ModeloDocumentoModule)
      },
      {
        path: 'help-center',
        data: {
          role: 'comprador'
        },
        loadChildren: () => import('app/modules/backoffice/help-center/help-center.module').then(m => m.HelpCenterModule)
      },
      {
        path: 'minhaconta',
        loadChildren: () => import('app/modules/comprador/profile-comprador/profile-comprador.module').then(m => m.ProfileCompradorModule)
      },
      {
        path: 'cotacao',
        loadChildren: () => import('app/modules/comprador/comprador-cotacao/comprador-cotacao.module').then(m => m.CompradorCotacaoModule)
      },
      {
        path: 'aprovacao',
        loadChildren: () => import('app/modules/comprador/aprovacao/aprovacao.module').then(m => m.AprovacaoModule)
      },
      { path: 'error/403', loadChildren: () => import('app/modules/errors/error-403/error-403.module').then(m => m.Error403Module) },
      { path: 'error/404', loadChildren: () => import('app/modules/errors/error-404/error-404.module').then(m => m.Error404Module) },
      { path: '**', redirectTo: '/error/404' }

    ]
  },

  // Admin routes
  {
    path: 'backoffice',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      role: 'backoffice'
    },
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'dashboard',
        component: BackofficeDashboardComponent,
        data: {
          role: 'backoffice'
        },
      },
      {
        path: 'clientes',
        data: {
          create: true,
          read: true,
          update: true,
          delete: false,
        },
        loadChildren: () => import('app/modules/backoffice/cliente/cliente.module').then(m => m.ClienteModule)
      },
      {
        path: 'usuarios',
        data: {
          create: true,
          read: true,
          update: true,
          delete: false,
        },
        loadChildren: () => import('app/modules/backoffice/usuarios/usuarios.module').then(m => m.UsuariosModule)
      },
      {
        path: 'fornecedores',
        data: {
          create: true,
          read: true,
          update: true,
          delete: false,
        },
        loadChildren: () => import('app/modules/backoffice/fornecedor/fornecedor.module').then(m => m.FornecedorModule)
      },
      {
        path: 'documentos',
        loadChildren: () => import('app/modules/comprador/comprador-documento/comprador-documento.module').then(m => m.CompradorDocumentoModule)
      },
      {
        path: 'requisicao',
        // loadChildren: () => import('app/modules/requisicao-documentos/requisicao-documentos.module').then(m => m.RequisicaoDocumentosModule)
        children: [
          {
            path: "exibir/:id",
            component: ExibirAprovarRequisicaoComponent,
            resolve: {
              requisicaoDocumento: RequisicaoDocumentoResolver
            }
          },
          {
            path: "criar",
            component: CriarEditarRequisitacaoDocumentoComponent,
          },
          {
            path: "editar/:id",
            component: CriarEditarRequisitacaoDocumentoComponent,
            resolve: {
              requisicaoDocumento: RequisicaoDocumentoResolver
            }
          },
        ]
      },
      {
        path: 'modelo-documento',
        data: {
          role: 'backoffice'
        },
        loadChildren: () => import('app/modules/modelo-documento/modelo-documento.module').then(m => m.ModeloDocumentoModule)
      },
      {
        path: 'localizar-fornecedores',
        loadChildren: () => import('app/modules/localizador-fornecedores/localizador-fornecedores.module').then(m => m.LocalizadorFornecedoresModule)
      },
      {
        path: 'minhaconta',
        loadChildren: () => import('app/modules/backoffice/profile-backoffice/profile-backoffice.module').then(m => m.ProfileBackofficeModule)
      },
      { path: 'error/403', loadChildren: () => import('app/modules/errors/error-403/error-403.module').then(m => m.Error403Module) },
      { path: 'error/404', loadChildren: () => import('app/modules/errors/error-404/error-404.module').then(m => m.Error404Module) },
      { path: '**', redirectTo: '/error/404' }
    ]
  },
  // Errors
  {
    path: 'error',
    component: LayoutComponent,
    // data: {
    //     layout: 'empty'
    // },
    children: [
      { path: '404', loadChildren: () => import('app/modules/errors/error-404/error-404.module').then(m => m.Error404Module) },
      { path: '403', loadChildren: () => import('app/modules/errors/error-403/error-403.module').then(m => m.Error403Module) },
      { path: '500', loadChildren: () => import('app/modules/errors/error-500/error-500.module').then(m => m.Error500Module) }
    ]
  },
  { 
    path: '**', 
    redirectTo: '/auth/login' 
  }
];