import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Utils from 'app/shared/helpers/utils';
@Injectable()
export class AuthService implements OnDestroy
{
    // Private
    private _authenticated: boolean;
    private destroyed$ = new Subject();
    private readonly SERVER_URL = environment.apiEndpoint;
    private accessTokenRef = null;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _cookieService: CookieService,
    )
    {
        // Set the defaults
        this._authenticated = false;
    }
    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
      }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        // sessionStorage.setItem('access_token', token);
        
        // this.accessTokenRef = token;
        if(token){
            
            this._cookieService.set(environment.cookie.authName,token,null,environment.cookie.auth_path,environment.cookie.auth_domain);
        }else{
            AuthUtils.clearAuthCookie()
            // this._cookieService.delete(environment.cookie.authName);
        }
       
    }

    get accessToken(): string
    {
        
        if(this._cookieService.check(environment.cookie.authName) == false || this._cookieService.get(environment.cookie.authName) === undefined){
            // this._cookieService.delete(environment.cookie.authName)
            AuthUtils.clearAuthCookie()
            return null;
        }
        return this._cookieService.get(environment.cookie.authName);
        // return this.accessTokenRef;
        // return sessionStorage.getItem('access_token') == undefined || sessionStorage.getItem('access_token') === undefined ? null : sessionStorage.getItem('access_token');
    }

    // -----------------------------------------------------------------------------------------------------    
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('Você já esta logado.');
        }

        return this._httpClient.post(`${this.SERVER_URL}login`, credentials)
        .pipe(takeUntil(this.destroyed$))
        .pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage`
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        if (!this.accessToken) {
          this.accessToken = this._cookieService.get(environment.cookie.authName);
        }
      
        return this._httpClient.post(`${this.SERVER_URL}login`, {
          access_token: this.accessToken
        }).pipe(
          takeUntil(this.destroyed$),
          catchError(() => {
            sessionStorage.clear();
            this._router.navigateByUrl(environment.routes.auth.login);
            return of(false);
          }),
          mergeMap((response: any) => {
            if (response) {
              this.accessToken = response.access_token;
              this._authenticated = true;
              return of(true);
            } else {
              this._router.navigateByUrl(environment.routes.auth.login);
              return EMPTY;
            }
          })
        );      
        // .pipe(
        //     catchError(() => {
        //         sessionStorage.clear();
        //         this._router.navigate([environment.routes.auth.login]);
        //         // Return false
        //         return of(false);
        //     }),
        //     switchMap((response: any) => {

        //         // Store the access token in the local storage
        //         this.accessToken = response.access_token;

        //         // Set the authenticated flag to true
        //         this._authenticated = true;

        //         // Return true
        //         return of(true);
        //     })
        // );
    }

    sendForgotPasswordResetCode(email: string): Observable<any>
    {
        return this._httpClient.post(`${this.SERVER_URL}usuario/forgot-password`,{email});
    }
    resetPasswordWithCode(code: string,password: string,userId: string): Observable<any>
    {
        return this._httpClient.post(`${this.SERVER_URL}usuario/reset-password`,{userId,code,password});
    }
    

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        
        this.accessToken = null;
        // this._cookieService.delete(environment.cookie.authName);
        // this._cookieService.deleteAll();
        AuthUtils.clearAuthCookie();
        sessionStorage.clear();
        // Set the authenticated flag to false
        this._authenticated = false;
        this._httpClient.get(`${this.SERVER_URL}logout`).subscribe((res: any)=>{
            // // 
        },(error)=>{
            // // 
        });
        // Remove the access token from the local storage
        // sessionStorage.removeItem('access_token');
    
    // Return the observable
    return of(true);
    }

    /**
     * Check the authentication status
     */
    isLoggedIn(): Observable<boolean>
    {
        
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        if ( !this._cookieService.check(environment.cookie.authName) )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this._cookieService.get(environment.cookie.authName)))
        {
            return of(false);
        }
        // return of(false);

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    checkRolePermitted(role: string): Observable<boolean>
    {
        if (this.accessToken && AuthUtils.hasRole(this.accessToken,role))
        {
            return of(true);
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(false)
    }
    getRoles(): string[]
    {
        return AuthUtils.getRoles(this.accessToken);
    }
}
